<template>
  <div>
    <el-row v-for="(item, index) in messages" :key="index" class="databox-g">
      <el-col :span="10">
        <table style="padding: 0px 30px">
          <tr v-for="(value, key) in tableList" :key="key">
            <td width="120px">{{ value }}</td>
            <td>{{ item[key] }}</td>
          </tr>
        </table>
        <!-- 公共消息可以关闭 -->
        <div class="btnwrap-g" v-if="custType == 'public'">
          <el-button type="danger" @click="closeMsg(item)" size="mini">关闭消息</el-button>
        </div>
      </el-col>
      <el-col :span="12" :offset="2">
        <div class="cellscreen">
          <h4>{{ item.title }}</h4>
          <pre>{{ item.content }}</pre>
          <img
            :src="'https://server.solar960.com' + item.img"
            alt
            width="100%"
            style="padding: 10px 0"
            v-if="item.img"
          />
        </div>
      </el-col>
    </el-row>
    <el-pagination
      :page-size="15"
      :pager-count="9"
      :current-page.sync="pageNumber"
      layout="total, prev, pager, next"
      :total="total"
      hide-on-single-page
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
import { getMessageList, closeMessage, getUserMessage } from "@/api/api";
export default {
  // message调用，type=public； bizdetail,userdetail,storedetail 调用，type=private
  //根据custId = public，取所有公共消息。=id时，取该ID的私人消息。 按钮显示控制custId=public 时，显示删除按钮.不能删除对私消息。
  props: ["custId", "custType"],

  data() {
    return {
      pageNumber: 1,
      tableList: {
        msgSn: "编号",
        state: "状态",
        custId: "对象ID",
        custType: "消息类别",
        sender: "发送人",
        genTime: "生成时间"
      },
      settingHistory: {
        settingSn: "",
        objId: "",
        objName: "",
        setting: "",
        remark: "",
        genTime: "",
        operator: ""
      },
      messageLog: {
        id: "",
        custId: "",
        msgSn: "",
        state: "", // -1：已删，0：未读，1：已读
        genTime: ""
      },
      rules: {
        title: [{ required: true, message: "必填", trigger: "blur" }],
        content: [{ required: true, message: "必填", trigger: "blur" }]
      },
      //
      total: 0,
      messages: []
    };
  },
  mounted() {
    if (this.custType == "admin") {
      this.getMessageListFn();
    } else {
      this.getUserMessageFn();
    }
  },
  methods: {
    getUserMessageFn() {
      const that = this;
      getUserMessage({
        id: that.custId,
        page: that.pageNumber
      }).then(res => {
        that.messages = res.data.list;
        that.total = res.data.total;
      });
    },
    getMessageListFn() {
      const that = this;
      getMessageList({
        page: that.pageNumber
      }).then(res => {
        that.messages = res.data.list;
        that.total = res.data.total;
      });
    },
    closeMsg(item) {
      //停止显示一个公共消息时，消息内容的isShow等于"0"; messageLog所有该消息的状态改为删除。
      const that = this;
      this.$prompt("请输入备注", "关闭消息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\S{1,}$/,
        inputErrorMessage: "请输入备注",
        closeOnClickModal: false
      })
        .then(({ value }) => {
          closeMessage({
            msgSn: item.msgSn,
            remark: value
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "关闭消息成功"
              });
              if (that.custType == "user") {
                that.getUserMessageFn();
              } else {
                that.getMessageListFn();
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "取消"
          });
        });
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      if (this.custType == "admin") {
        this.getMessageListFn();
      } else {
        this.getUserMessageFn();
      }
    }
  }
};
</script>

<style scoped>
.cellscreen {
  width: 375px;
  box-sizing: border-box;
  padding: 0xp 20px;
}
</style>